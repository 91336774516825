<template>
    <div class="wrapper">
        <table class="expand_table">
            <tr v-show="row.description">
                <td class="expand-td-left">隐患点说明: </td>
                <td class="expand-td-right">{{ row.description }}</td>
            </tr>
            <tr v-show="row.solution_proposal">
                <td class="expand-td-left">对策建议: </td>
                <td class="expand-td-right">{{ row.solution_proposal }}</td>
            </tr>
            <tr v-show="row.involve_interface">
                <td class="expand-td-left">相关类/专业接口: </td>
                <td class="expand-td-right">{{ row.involve_interface }}</td>
            </tr>
            
        </table>
    </div>
</template>
<script>
    export default {
        name: "riskListExpand",
        props: {
            row: Object
        }
    }
</script>
<style scoped>
    .wrapper{
        text-align: center;
    }
    .expand_table {
        margin: 0 auto;
        width:80%;
        border-collapse: collapse;
    }
    .expand-td-left{
        padding: 0 20px;
        width:150px;
        text-align: right;
        background-color: inherit;
        border: none;
        color:#888;
    }
    .expand-td-right{
        background-color: inherit;
        border: none;
        font-size: 14px;
    }
</style>